import { useState } from 'react';
import './App.css';
import Authorization from './components/authorization/Authorization';
import TopNav from './components/header/TopNav';
import AddProduct from './components/addProduct/AddProduct';
import ViewProducts from './components/setProducts/ViewProducts';
import ShowOrders from './components/orders/ShowOrders';
import fetchPass from './service/pass';

function App() {
  const[showSite,setShowSite]=useState(null)
  const[navNow,setNavNow]=useState('orders');
  useState(async()=>{
    if(!localStorage.getItem('pass')){
       setShowSite('verification')
    }else{
      let pass = await fetchPass.post(localStorage.getItem('pass'));
      pass === localStorage.getItem('pass') && setShowSite('show')
    }
  },[])
  return (
    <div className="App">
      {showSite === 'verification' && <Authorization setShowSite = {setShowSite}/>} 
      {showSite === 'show'  &&
        <>
          <TopNav navNow={navNow} setNavNow={setNavNow} />
          <main className="pt-4 min-h-screen bg-[#CCFFCC]">
              {navNow === 'setProducts' && <ViewProducts/>}
              {navNow === 'addProduct' && <AddProduct/>}
              {navNow === 'orders' && <ShowOrders/>}
          </main>
        </>
      }
        
      
      
    </div>
  );
}

export default App;
