import { useState } from "react"
import CreateTea from "./CreateTea"
import CreateTeaware from "./CreateTeaware"
const AddProduct = () =>{
    const[selectProduct,setCelectProdut]=useState(null);
    return(
        <>
            <div className="flex justify-center gap-4 items-center">
                <button className={`w-[100px] text-center p-2 rounded-md hover:bg-[#66CC66] ${selectProduct === 'tea' && 'bg-[#66CC66]'} cursor-pointer`} onClick={(e)=>{setCelectProdut(e.target.value)}} value='tea'>Чай</button>
                <button className={`w-[100px] text-center p-2 rounded-md hover:bg-[#66CC66] ${selectProduct === 'teaware' && 'bg-[#66CC66]'} cursor-pointer`} onClick={(e)=>{setCelectProdut(e.target.value)}} value='teaware'>Посуда</button>
            </div>
            {selectProduct === 'tea' && <CreateTea/>}
            {selectProduct === 'teaware' && <CreateTeaware/>}
        </>
    )
}

export default AddProduct;