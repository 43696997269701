import { useState } from "react";
import fetchPass from "../../service/pass";

const Authorization = ({setShowSite}) =>{
    const[timer,setTimer]=useState(false)
    async function clickEvent(event) {
        if(event.key === 'Enter'){
            let pass =await fetchPass.post(event.target.value);
            if(pass){   
                localStorage.setItem('pass', pass);   
                setTimer(false)
                setShowSite('show') 
            }else{
                setTimer(true)
                setTimeout(() => {
                    setTimer(false)
                }, 3000);
            } 
        }       
    }
    return(
        <div className="mx-auto flex items-center justify-center w-screen h-screen">
                <input className="border border-[black]" type="password" name="pass" id="pass" disabled={timer && true}  onKeyDown={(event)=>clickEvent(event)} />
              
            
        </div>
    )
}

export default Authorization;