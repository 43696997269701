
import fetchProducts from "../../service/productApi";
import { useEffect, useState } from "react";
import HOST from "../../service/host";

const InputReqProduct = ({category,setArrReq}) =>{
    const[loadData,setLoadData]=useState(false)
    useEffect(()=>{
        async function getData() {
            let data = await fetchProducts.getAll();
            let arrProducts = data.filter((item)=>item.category !== category);
            setLoadData(arrProducts);
        }
        getData();
    },[])
    function addReq(item){
        setArrReq((oldArr)=>{
            if(oldArr){
                let result = oldArr.filter((oldItem)=> oldItem === item );
                if(result.length === 0){
                    return [...oldArr,item]
                }else return [...oldArr]
               
            }else return [item];  
        })
    }
    return(
        <div className="mt-2 p-2 border border-[grey] flex flex-col justify-center gap-2">
        {loadData && loadData.map((item)=>{
            return(
                <div key={item._id} className="flex justify-start items-center gap-2 border-b-[1px] border-[grey] p-2" >
                    <button type="button" onClick={()=>addReq(item)}><img src={`/addReq.svg`} width={30} height={30} alt="REq" /></button>
                    <img src={`${HOST}/api/products/img/${item.imgId[0]}`} width={30} height={30} alt="REq" />
                    <h4>{item.title}</h4>
                    <p>{item.type}</p>
                </div>
            )
        })}
        </div>
    )
}

export default InputReqProduct;