import { useState } from "react";
import fetchProducts from "../../service/productApi";
import ReactModal from "react-modal";

const DeleteProduct = ({item,setAllProducts}) =>{
    const[showModal,setShowModal]=useState(false);
    const[showLoader,setShowLoader]=useState(false);
    
    async function deleteItem(){
        setShowLoader(true);
        await fetchProducts.delete(item._id)
        await setAllProducts((prevArr)=> prevArr.filter((product)=>product !== item))  
    }
    return(
        <>
            <button onClick={()=>setShowModal(true)} className=" hover:bg-red-300 p-2 rounded-xl">
                <img  src='/cancel.svg' alt="Cancel" width={20} height={20}/>
            </button>
           
                <ReactModal 
                    isOpen={showModal}
                    contentLabel="Delete Product"
                    ariaHideApp={false}
                    >
                    <button className="ml-auto mr-0 block" onClick={()=>setShowModal(false)}><img  src='/cancel.svg' alt="Cancel" width={20} height={20}/></button>
                    {!showLoader &&
                        <div className="flex flex-col items-center gap-5">
                            <p className="test-[20px]">Ты точно хочешь удалить ? <span className="test-[20px] font-extrabold">' { item.title } '</span></p>
                            <div className="flex gap-4">
                                <button onClick={()=>deleteItem()} className="w-[100px] h-[30px] bg-green-600 rounded-xl text-white hover:bg-green-900">ДА</button>
                                <button onClick={()=>setShowModal(false)} className="w-[100px] h-[30px] bg-red-600 rounded-xl text-white hover:bg-red-900">НЕТ</button>
                            </div>
                        </div>
                    }
                    {showLoader && <div className=" h-screen flex justify-center items-center"><img   src="/preloader.gif" alt="preloader"/></div>}
                    
                </ReactModal>
            
        </>
    )
};

export default DeleteProduct;

