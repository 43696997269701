import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import fetchProducts from "../../service/productApi";
import HOST from "../../service/host";
const AddOrderProduct = ({setProductCart}) =>{
    const[addModal,setAddModal]=useState(false);
    const[products,setProducts]=useState(null);
    const[amount,setAmount]=useState(1);
    useEffect(()=>{
        fetchProducts.getAll().then((data)=>setProducts(data)).catch((err)=>setAddModal(false));
    },[addModal])
    function addEvent(id,title,item,price,initialAmount,img){
        if(+amount > 1){
            const divider = price / 1000;

            let result = +amount * divider;

            setProductCart((prevArr)=>{
                let newArr = [...prevArr];
                newArr.push({
                    id: id,
                    title: title,
                    amount: item,
                    price: result,
                    img: img

                });
                setAddModal(false)
                return newArr;
            })
        }else if(amount === 1){
            setProductCart((prevArr)=>{
                let newArr = [...prevArr];
                newArr.push({
                    id: id,
                    title: title,
                    amount: item,
                    price: +price,
                    img: img

                });
                setAddModal(false)
                return newArr;
            })

        }else alert('Введи количество')
        
    }

    return(
        <>
            <button onClick={()=>setAddModal(true)} className=" hover:bg-neutral-400 p-2 rounded-xl">
                <img src="/addReq.svg" width={30} height={30} alt="Add Product"/>
            </button>

            <ReactModal 
                isOpen={addModal}
                contentLabel="Add Product"
                ariaHideApp={false}   
            >
                <button onClick={()=>setAddModal(false)} className=" hover:bg-neutral-400 p-2 rounded-xl ml-auto mr-0 block">
                    <img src="/cancel.svg" width={30} height={30} alt="Add Product"/>
                </button>
            {products &&
                <ul className="flex flex-col gap-2">
                    {products.map((item)=>{
                        return(
                            <li className="flex gap-2" key={Math.random()}>
                                <img src={`${HOST}/api/products/img/${item.imgId[0]}`} width={60} height={60} alt="img" />
                                <p className="w-[200px] ">{item.title}</p>
                                <input className="w-[200px] " type="number" name="amount" id="amount" placeholder={item.amount} disabled={item.amount > 1 ? false : true} onBlur={(e)=>{setAmount(e.target.value)}}/>
                                <button onClick={()=>{addEvent(item._id,item.title,+amount,item.price,item.amount,`${HOST}/api/products/img/${item.imgId[0]}`)}} className=" hover:bg-neutral-400 p-2 rounded-xl  block">
                                    <img src="/addReq.svg" width={30} height={30} alt="Add Product"/>
                                </button>
                            </li>
                        )
                    })}
                    
                </ul>
            }
            </ReactModal>
        </>
        
    )
}
export default AddOrderProduct;