import { useState } from "react";
import fetchOrdersArchive from "../../service/ordersArchive";

const PostOrder = ({item}) =>{
    const[loader,setLoader]=useState(false)
    async function postToArchive(){
        setLoader(true);
        await fetchOrdersArchive.post(item).then(data=>alert('Успех')).catch((err)=>alert(err));
        setLoader(false);
    }
    return(
        <>
        {loader && <div className="left-0 top-0 h-screen w-screen bg-white bg-opacity-80 flex justify-center items-center absolute"><img   src="/preloader.gif" alt="preloader"/></div>}
        <button onClick={postToArchive} className=" hover:bg-green-700 p-1 rounded-xl"><img src="ok.svg" alt="OK" width={30} height={30}/></button>
        </>
    )   
}

export default PostOrder;