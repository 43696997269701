import HOST from "./host";

const fetchOrders ={
    urlApi : `${HOST}/api/orders`,

    getAll : async function(){
        return fetch(this.urlApi,{
          method: 'GET'
        })
        .then(response => response.text())
        .then(result =>{
          console.log(`Ответ: Данные получены`); 
          return JSON.parse(result);
        })
        .catch(error => console.log('error', error));
      },

    delete : async function(id){
    return fetch(`${this.urlApi}/${id}`, {
        method: 'DELETE',
    })
    .then(response => {
        if (response.status === 200) {
            return response
        } else {
        console.error('Ошибка при удалении ресурса и изображений.');
            return response
        }
    })
    .catch(error => {
        console.error('Ошибка при выполнении запроса:', error);
    });
    },
    put :  async function(id,data){
      
        return  fetch(`${this.urlApi}/${id}`, {
          headers: {
            'Content-Type': 'application/json' 
          },
          method: 'PUT',
          body: JSON.stringify(data) , 
          })
          .then(response => {
            if (response.status === 200) {
                console.log(response)
              return'Ресурс изменен.';
            } else {
              console.error('Ошибка при изменении.');
              return 'Ошибка при изменении.'
            }
          })
          .catch(error => {
            console.error('Ошибка при выполнении запроса:', error);
            return ('Ошибка при выполнении запроса:', error)
          });
        }

}

export default fetchOrders;