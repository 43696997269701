import { useState } from "react";
import fetchProducts from "../../service/productApi";
import HOST from "../../service/host";
import DeleteProduct from "./DeleteProduct";
import SetProduct from "./SetProduct";
const ViewProducts = () =>{
    const[allProducts,setAllProducts]=useState(null);
    useState(()=>{
        fetchProducts.getAll()
        .then((data)=>setAllProducts(data))
        .catch((error)=>console.log(error))
    },[]);
    const grivnaSymbol = '\u20B4';

    return(
        <>
            {!allProducts && <div className=" h-screen flex justify-center items-center"><img   src="/preloader.gif" alt="preloader"/></div>}
            {allProducts &&
                <ul className="flex justify-start items-start flex-wrap gap-2 p-2">
                    {allProducts.map((item)=>{
                        return(
                            <li key={item._id}>
                                <ul className=" w-[200px] h-[400px] flex flex-col gap-2 justify-center items-center border-[1px] border-[grey]">
                                    <li><img className="w-[200px] h-[200px] object-cover" src={`${HOST}/api/products/img/${item.imgId[0]}`} alt={item.title} /></li>
                                    <li className="w-[200px] font-bold">{item.title}</li>
                                    <li className="border-b-2 border-[grey] " >{item.price} <span className="font-semibold">{grivnaSymbol}</span></li>
                                    <li className="border-b-2 border-[grey] " >{item.amount} <span className="font-semibold">{item.amount === '1' ? 'шт' : 'грамм'}</span></li>
                                    <li><div className={`w-[25px] h-[25px] rounded-full  ${item.show ==='true' ? 'bg-green-800' : 'bg-yellow-400'}`}></div></li>
                                    <li className="flex  justify-around w-full p-2 border-y-[1px] border-[grey]">
                                        <DeleteProduct item={item} setAllProducts={setAllProducts}/>
                                        <SetProduct product={item}/>
                                    </li>
                                </ul>
                            </li>
                        )
                    })}
                </ul>
            }
        </>
    )
}

export default ViewProducts;