import HOST from "./host";

const fetchOrdersArchive ={
    urlApi : `${HOST}/api/ordersArchive`,
    post :  async function(data){
      
        return  fetch(`${this.urlApi}`, {
          headers: {
            'Content-Type': 'application/json' 
          },
          method: 'POST',
          body: JSON.stringify(data) , 
          })
          .then(response => {
            if (response.status === 200) {
                console.log(response)
              return'Ресурс отправлен в архив.';
            } else {
              console.error('Ошибка при изменении.');
              return 'Ошибка при изменении.'
            }
          })
          .catch(error => {
            console.error('Ошибка при выполнении запроса:', error);
            return ('Ошибка при выполнении запроса:', error)
          });
        }
}

export default fetchOrdersArchive;