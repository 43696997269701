
const ArrCart = ({product,index,setProductCart}) =>{
    const grivnaSymbol = '\u20B4';

    function setEvent(value){
        const divider = product.price / product.amount;
        let result = value * divider;
        setProductCart(prevArr =>{
            let newArr = [...prevArr];
            newArr[index].price = result;
            newArr[index].amount = +value;
            return newArr; 
        })
    }

    function deleteEvent(){
        setProductCart(prevArr =>{
            let newArr = [...prevArr];
            
            return newArr.filter((item)=> item !== product); 
        })
    }
    return(
        <li className="flex items-center p-2" >
            <img src={product.img} alt={product.title} width={50} height={50}/>
            <p className="w-[200px] border-r-2">{product.title}</p>
            <input onBlur={(e)=>setEvent(e.target.value)} className="w-[100px] border-r-2 text-center" type="number" disabled={product.amount > 1 ? false : true} name="amount" id="amount" placeholder={product.amount}/>
            <p className="w-[100px] border-r-2">{product.price} <span>{grivnaSymbol}</span></p>
            <button onClick={()=>deleteEvent()} className=" hover:bg-neutral-400 p-2 rounded-xl">
                <img src="/cancel.svg" width={15} height={15} alt="Add Product"/>
            </button>
        </li>
    )
};

export default ArrCart;