import { useState,useEffect } from "react";
const StepControle = ({step,initialPrice,full}) =>{

    let startCount = !step ? 1 : step.length === 3 ? step[1] : step[0] ;
    const[count,setCount]=useState(+startCount);
    const[price,setPrice]=useState(initialPrice)
    useEffect(()=>{
        if(step){
            let price = initialPrice / 1000;
            setPrice(()=>price * count)
        }
        
    })
    function clickPlus(){
        setCount((count)=>count + 1)
        setPrice(()=>initialPrice * (count+1))
    }
    function clickMinus(){
        setCount((count)=> count > 1 ? count - 1 : 1)
        setPrice(()=> count === 1 ? initialPrice : initialPrice * (count-1))
    }
    function clickSelect(e){
        let newCount = +e.target.value;
        setCount(newCount)
    }
    return(
        <div className="flex opacity-80 justify-center gap-2 flex-wrap mt-2 ">
            {!step && <div className={`${full ? 'w-[300px]' : 'w-full'}  flex h-12 bg-black justify-between items-center rounded-[10px]`}>
                <button className="text-[40px] text-white text-center grow " onClick={()=>clickMinus()}>-</button>
                <div className="bg-white w-[130px] h-5/6 rounded-[10px] text-[20px] font-medium flex items-center justify-center gap-2 text-center ">{+count}<span>шт</span> </div>
                <button className="text-[40px] text-white text-center grow " onClick={()=>clickPlus()}>+</button>
            </div>}

            {step &&  <div className={`${full ? 'w-[300px]' : 'w-full'}  flex h-12 bg-black justify-evenly items-center text-white text-[16px] font-medium rounded-[10px]`}>
                {step.map((step)=>{
                    if(step !== 0){
                        if(count === +step){
                            return <button key={Math.random()} className="bg-white text-black w-[85px] h-5/6 rounded-[10px]">{step} гр</button>
                        }else return <button key={Math.random()} className=" w-[85px]" onClick={(e)=> clickSelect(e)} value={step}>{step} гр</button>  
                    }
                         
                    })
                }
                </div>     
            }
    
            <p className="py-2 px-5 bg-orange-400 rounded-xl">{price} грн</p>
        </div>
    )
}
export default StepControle;