import HOST from "./host";

const fetchProducts = {
    urlApi : `${HOST}/api/products`,

    push : async function(data){
            return fetch(this.urlApi,{
                method: 'POST',
                body: data, 
            })
            .then(function(response) {
                if (response.status === 200) {
                    alert('Товар отправлен в Базу данных')
                } else {
                  alert("Ощибка на стороне сервера")
                  throw new Error('Ошибка: ' + response.status + response);
                }
              })
              .catch(function(error) {
                throw error;
              });
    },

    delete : async function(id){
      return fetch(`${this.urlApi}/${id}`, {
        method: 'DELETE',
      })
      .then(response => {
        if (response.status === 200) {

          return response
        } else {
          console.error('Ошибка при удалении ресурса и изображений.');
          
          return response
        }
      })
      .catch(error => {
        console.error('Ошибка при выполнении запроса:', error);
      });
    },

    put :  async function(id,formData){
      return  fetch(`${this.urlApi}/${id}`, {
          method: 'PUT',
          body: formData, 
        })
        .then(response => {
          if (response.status === 200) {
            return'Ресурс изменен.';
          } else {
            console.error('Ошибка при изменении.');
            return 'Ошибка при изменении.'
          }
        })
        .catch(error => {
          console.error('Ошибка при выполнении запроса:', error);
          return ('Ошибка при выполнении запроса:', error)
        });
      },
    

    getAll : async function(){
      return fetch(this.urlApi,{
        method: 'GET'
      })
      .then(response => response.text())
      .then(result =>{
        console.log(`Ответ: Данные получены`); // Добавьте эту строку для отладки ответа
        return JSON.parse(result);
      })
      .catch(error => console.log('error', error));
    }
}

export default fetchProducts;