import ReactModal from "react-modal";
import { Formik, Form, Field, ErrorMessage} from 'formik';
import { useState } from "react";
import ArrCart from "./ArrCart";
import fetchOrders from "../../service/orderApi";
import AddOrderProduct from "./AddOrderProduct";
const SetOrder = ({item}) =>{
    
    const[productCart,setProductCart]=useState(item.arrCart);
    const[showSetModal,setShowModal]=useState(false);
    
    const initialValues={
        firstName : item.firstName,
        lastName : item.lastName,
        methodDelivery : item.methodDelivery,
        postOfficeNumber : item.postOfficeNumber,
        phoneNumber : item.phoneNumber,
        arrCart : item.arrCart,
    };
    async function handleSubmit(values){
        let newObj={};
        for (const key in values) {
            if (Object.hasOwnProperty.call(values, key)) {
                const element = values[key];
                if(element !== initialValues[key]){
                    newObj[key] = element;
                } 
            }
        }
        if(initialValues.arrCart !== productCart){newObj.arrCart = productCart}
        
        fetchOrders.put(item._id,newObj)

    }
    return( 
        <>
            <button onClick={()=>setShowModal(true)} className=" hover:bg-neutral-400 p-2 rounded-xl">
                <img src="/change.svg" width={20} height={20} alt="Change Product"/>
            </button>
            <ReactModal 
                isOpen={showSetModal}
                contentLabel="SET Product"
                ariaHideApp={false}   
            >
                <button className="ml-auto mr-0 block" onClick={()=>setShowModal(false)}><img  src='/cancel.svg' alt="Cancel" width={20} height={20}/></button>
                <Formik
                    initialValues={initialValues}
                    // validate={validateForm}
                    onSubmit={handleSubmit}
                    >{({ isSubmitting }) => (
                        <Form >
                            <div className=" flex justify-center gap-4">
                                <ul className="flex flex-col gap-2">
                                    <li className="flex gap-2">
                                        <label className="w-[200px]" htmlFor='firstName'> Имя :</label>
                                        <Field className='w-[400px] border border-solid border-[grey] rounded px-2 text-center'  as='input'  id='firstName' name='firstName' placeholder={initialValues.firstName}/>
                                    </li>
                                    <li className="flex gap-2">
                                        <label className="w-[200px]" htmlFor='lastName'> Фамилия :</label>
                                        <Field className='w-[400px] border border-solid border-[grey] rounded px-2 text-center'  as='input'  id='lastName' name='lastName' placeholder={initialValues.lastName}/>
                                    </li>
                                    <li className="flex gap-2">
                                        <label className="w-[200px]" htmlFor='methodDelivery'> Способ доставки :</label>
                                        <Field className='w-[400px] border border-solid border-[grey] rounded px-2 text-center' as='select'  id='methodDelivery' name='methodDelivery'>
                                            <option value="novaPoshta">НОВА ПОШТА</option>
                                            <option value="ukrPoshta">УКРПОШТА</option>
                                        </Field>
                                    </li>
                                    <li className="flex gap-2">
                                        <label className="w-[200px]" htmlFor='postOfficeNumber'> Отделение :</label>
                                        <Field className='w-[400px] border border-solid border-[grey] rounded px-2 text-center'  as='input'  id='postOfficeNumber' name='postOfficeNumber' placeholder={initialValues.postOfficeNumber}/>
                                    </li>
                                    <li className="flex gap-2">
                                        <label className="w-[200px]" htmlFor='phoneNumber'> Телефон :</label>
                                        <Field className='w-[400px] border border-solid border-[grey] rounded px-2 text-center'  as='input'  id='phoneNumber' name='phoneNumber' placeholder={initialValues.phoneNumber}/>
                                    </li>
                                </ul>
                                <ul className="flex flex-col items-center gap-2 w-[500px] text-center">
                                    {productCart.map((item,index)=>{
                                        return <ArrCart key={Math.random()} product={item} index={index} setProductCart={setProductCart}/>
                                    })}
                                    <AddOrderProduct setProductCart={setProductCart}/>
                                </ul>  
                            </div>
                            
                            
                            <button className="w-[300px] h-12 mt-5 mx-auto flex justify-center items-center text-[19px] text-white opacity-80 hover:opacity-100 rounded-[10px] font-semibold bg-[#0E8388]" type="submit" disabled={isSubmitting}>
                                Відправити
                            </button>
                        </Form>)}
                        
                </Formik>

            </ReactModal>
        </>
    )
}

export default SetOrder;