
const TopNav = ({navNow,setNavNow}) =>{
    const navItemsArr=[
        {value:'addProduct',name:'Добавить продукт'},
        {value:'setProducts',name:'Изменить товар'},
        {value:'orders', name:'Заказы'}
    ]
    return(
        <nav className="w-screen h-[50px] bg-[#669966]">
            <ul className="h-full flex justify-center items-center gap-4 text-slate-700 ">
                {
                    navItemsArr.map((item)=>{
                        return <li key={item.value} ><button onClick={(e)=>setNavNow(e.target.value)}  className={`p-2 rounded-md hover:bg-[#66CC66] ${navNow === item.value && 'bg-[#66CC66]'}`} value={item.value}>{item.name}</button></li>
                    })
                }
            </ul>
            
        </nav>
    )
}

export default TopNav;