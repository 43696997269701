import HOST from "./host";

const fetchPass = {
    urlApi: `${HOST}/api/pass`,
    post: async function (data) {
      return fetch(`${this.urlApi}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({ pass: data }),
      })
        .then(response => {
          if (response.status === 200) {
            return response.body.getReader().read().then(({ done, value }) => {
              
              const responseBody = new TextDecoder().decode(value);
              console.log('Прочитано:', responseBody);
              return responseBody;
            });
          } else{
            console.error('Ошибка при изменении.');
            return null;
          }
        })
        .catch(error => {
          console.error('Ошибка при выполнении запроса:', error);
          return null;
        });
    },
  };
  
  export default fetchPass;
  