import { Formik, Form, Field, ErrorMessage} from 'formik';
import StepControle from "./StepControle";
import { useEffect, useState } from "react";
import fetchProducts from '../../service/productApi';
import InputReqProduct from '../ReqProduct/InputReqProduct';
import HOST from '../../service/host';

const imgArray=[];
let buttonsArr=[];
let reqArr;

const englishInputRegex = /^[a-zA-Z0-9`~!@#$%^&*()\-=+\[{}\]|;:'",<.>\/?\s]+$/;

const initialValues = {
    category:'tea',
    type:'Red',
    title:'',
    titleCn:'',
    country:'China',
    region:'',
    year:'',
    season:'fall',
    description:'',
    amount:8888,
    price: 6666,
    show:true,

    titleEN: '',
    regionEN: '',
    descriptionEN: '',
}

const validateForm = values => {
    const errors = {};
    if (!values.title) {
      errors.title = 'Братик не втыкай заполни поле с названием товара';
    }
    if(englishInputRegex.test(values.title)){errors.title = 'Вводи на украинском';}
    if (!values.titleCn) {
        errors.titleCn = 'Зачем китайский учил ?';
      }
    if (!values.region) {
        errors.region = 'Яо не сказал откуда товар ?';
    }
    if(englishInputRegex.test(values.region)){errors.region = 'Вводи на украинском';}
    if (!values.year) {
        errors.year = 'Яо какой год ооО ?';
    }
    if (!values.description) {
        errors.description = 'Вместо тысячи слов хотя бы одно';
    }
    if(englishInputRegex.test(values.description)){errors.description = 'Вводи на украинском';}
    if (!values.amount) {
        errors.amount = 'Хули тут так мало ?';
    }else if(typeof values.amount !== 'number'){
        errors.amount = 'Только цыфры'
    }
    if (!values.price) {
        errors.price = 'На пенек сел должен был цену указать';
    }

    
    if (!values.titleEN) {
        errors.titleEN = 'Введи назавние чая на английском !';
      }
    if (!values.regionEN) {
        errors.regionEN = 'Введи регион на английском !';
    }
    if (!values.descriptionEN) {
        errors.descriptionEN = 'Введи описание на английском !';
      }
    if (!values.route) {
        errors.route = 'Введи путь для роутинга !';
    }
    if(!englishInputRegex.test(values.titleEN)){errors.titleEN = 'Вводи на английском';}
    if(!englishInputRegex.test(values.regionEN)){errors.regionEN = 'Вводи на английском';}
    // if(!englishInputRegex.test(values.descriptionEN)){errors.descriptionEN = 'Вводи на английском';}
    return errors;
}


const CreateTea = () =>{
   const[stepArr,setStepArr]=useState([25,50,100]);
   const[initialPrice,setInitialPrice]=useState(1000);
   const[showImg,setShowImg]=useState(null);
   const[showReq,setShowReq]=useState(false);
   const[showLoader,setLoader]=useState(false);
   const[arrReq,setArrReq]=useState(null);
   useEffect(()=>{buttonsArr = stepArr},[stepArr])
   useEffect(()=>{reqArr = arrReq},[arrReq])
            function setValueStep(e,index){
                setStepArr((oldArr)=>{
                    let newArr = [...oldArr];
                    let validate = true;
                    newArr.forEach((item,index)=>{
                        if(item === e.target.value){validate = false}  
                    })
            if(validate){
                if(+e.target.value === 0 && oldArr.length > 1){
                    newArr.splice([index],1)
                    console.log(newArr)
                }else{         
                    // eslint-disable-next-line no-unused-expressions
                    +e.target.value === 0 ? null : newArr[index]=+e.target.value;    
                }
                return newArr;
            }else return oldArr; 
        })
   }
   
   const handleImageChange = (event) => {
        const files = Array.from(event.target.files);
        files.forEach((item)=>{
            imgArray.push(item)
        })
        const imageUrls = files.map((item) => URL.createObjectURL(item));
        setShowImg(imageUrls);
  };

  
  const handleSubmit = async(values, { setSubmitting,setValues }) => {
    const formData = new FormData();
    for (const key in values) {
        if (Object.hasOwnProperty.call(values, key)) {
            const element = values[key];
            formData.append(key,element)
        }
    }                               
    switch (values.type) {
        case 'Green':
            formData.append('typeUA', 'Зелений')
            break;
        case 'White':
            formData.append('typeUA', 'Білий')
            break;
        case "Yellow":
            formData.append('typeUA', 'Жовтий')
            break; 
        case "Wulong":
            formData.append('typeUA', 'Улун')
            break;
        case "Red":
            formData.append('typeUA', 'Червоний')
            break;
        case "Black":
            formData.append('typeUA', 'Чорний')
            break;  
        case "Sheng Puer":
            formData.append('typeUA', 'Шен Пуер')
            break; 
        case "Shu Puer":
            formData.append('typeUA', 'Шу Пуер')
            break; 
        case 'GABA':
            formData.append('typeUA', 'GABA')
            break;  
        default:  
            formData.append('typeUA', 'GABA')
            break;  
    }
    switch (values.country) {
        case 'Ukraine':
            formData.append('countryUA', 'Україна')
            break;
        case 'Japan':
            formData.append('countryUA', 'Японія')
            break;
        case "China":
            formData.append('countryUA', 'Китай')
            break; 
        case "Taiwan":
            formData.append('countryUA', 'Тайвань')
            break;  
        default:  
        formData.append('countryUA', 'Китай')
            break;  
    }
    switch (values.category) {
        case 'tea':
            formData.append('categoryUA', 'Чай')
            break;
        case 'teaware':
            formData.append('categoryUA', 'Посуд')
            break; 
        default:  
        formData.append('categoryUA', 'Чай')
        break;  
    }
    switch (values.season) {
        case "spring":
            formData.append('seasonUA', 'Весна')
            break;
        case "summer":
            formData.append('seasonUA', 'Літо')
            break; 
        case "fall":
            formData.append('seasonUA', 'Осінь')
            break;  
        default:  
        formData.append('seasonUA', 'Осінь')
        break;
    }
    // if(reqArr){
    //     let arr = reqArr.map((item)=>item._id)
    //     console.log(JSON.stringify(arr))
    //     formData.append('reqArr',JSON.stringify(arr));
    // }else return alert('Доюавь рекомендации');

    formData.append('buttonsArr',buttonsArr)
    if(imgArray.length > 0){
        imgArray.forEach((img)=>{
            formData.append('imgArr',img)
        })
        
    }else return alert('Добавь изображения !')
   
    setLoader(true);
    await fetchProducts.push(formData);
    setLoader(false);
    window.location.reload();
}
    return(
        <Formik
        initialValues={initialValues}
        validate={validateForm}
            onSubmit={handleSubmit}
            
            >
        {({ isSubmitting }) => (
            <Form className="pb-4">
                {showLoader &&
                    <div className='w-full h-screen top-0 z-10 flex justify-center items-center bg-slate-200 fixed'>
                        <img className='mx-auto ' src="/preloader.gif" alt="Load"/>
                    </div>
                }
                
                <div className="flex justify-center gap-4">
                    <ul className="mt-10  w-[500px] p-2 border-solid border-[1px] border-[grey] flex flex-col justify-start items-center">
                            <li className="flex w-full flex-col gap-1 ">
                                <label htmlFor="title">Название товара</label>
                                <Field className=' border border-solid border-[grey] rounded px-2 text-center'  as='input' id="title" name="title"/>
                                <ErrorMessage name="title" component="p" className="text-red-500"/>
                            </li>
                            <li className="flex w-full flex-col gap-1 ">
                                <label htmlFor="title">Название товара на китайском</label>
                                <Field className='border border-solid border-[grey] rounded px-2 text-center'  as='input' id="titleCn" name="titleCn" />
                                <ErrorMessage name="titleCn" component="p" className="text-red-500"/>
                            </li>
                            <li className="flex w-full flex-col gap-1 ">
                                <label htmlFor="type">Тип чая</label>
                                <Field className=' border border-solid border-[grey] rounded px-2 text-center' as='select' id="type" name="type" >
                                    <option value="Green">Зеленый</option>
                                    <option value="White">Белый</option>
                                    <option value="Yellow">Желтый</option>
                                    <option value="Wulong">Улун</option>
                                    <option value="Red">Красный</option>
                                    <option value="Black">Черный</option>
                                    <option value="ShengPuer">Шен Пуер</option>
                                    <option value="ShuPuer">Шу Пуер</option>
                                    <option value="GABA">ГАБА</option>
                                </Field>
                            </li>
                            <li className="flex w-full flex-col gap-1 ">
                                <label htmlFor="country">Страна производитель</label>
                                <Field className=' border border-solid border-[grey] rounded px-2 text-center' as='select' id="country" name="country" >
                                    <option value="Ukraine">Украина</option>
                                    <option value="China">Китай</option>
                                    <option value="Taiwan">Тайвань</option>
                                    <option value="Japan">Япония</option>
                                </Field>
                            </li>
                            <li className="flex w-full flex-col gap-1 ">
                                <label htmlFor="region">Регион</label>
                                <Field className=' border border-solid border-[grey] rounded px-2 text-center'  as='input' id="region" name="region" />
                                <ErrorMessage name="region" component="p" className="text-red-500"/>
                            </li>
                            <li className="flex w-full flex-col gap-1 ">
                                <label htmlFor="year">Год:</label>
                                <Field className=' border border-solid border-[grey] rounded px-2 text-center' as='input' type='number' id="year" name="year" />
                                <ErrorMessage name="yeat" component="p" className="text-red-500"/>
                            </li>
                            <li className="flex w-full flex-col gap-1 ">
                                <label htmlFor="season">Сезон :</label>
                                <Field className=' border border-solid border-[grey] rounded px-2 text-center' as='select' id="season" name="season" >
                                    <option value="spring">Весна</option>
                                    <option value="summer">Лето</option>
                                    <option value="fall">Осень</option>
                                </Field>
                            </li>
                            <li className="flex w-full flex-col gap-1 ">
                                <label htmlFor="description">Описание:</label>
                                <Field  className='text-[16px] focus:h-[500px] overflow-hidden border border-solid border-[grey] rounded px-2' as='textarea' id="description" name="description" />
                                <ErrorMessage name="description" component="p" className="text-red-500"/>
                            </li>
                            <li className="flex w-full flex-col gap-1 ">
                                <label htmlFor="amount">Количество в граммах :</label>
                                <Field className=' border border-solid border-[grey] rounded px-2 text-center'  as='input' type='number' id="amount" name="amount" />
                                <ErrorMessage name="amount" component="p" className="text-red-500"/>
                            </li>
                            <li className="flex w-full flex-col gap-1 ">
                                <label htmlFor="price">Цена за килограмм чая :</label>
                                <Field className=' border border-solid border-[grey] rounded px-2 text-center'  as='input' type='number' id="price" name="price" placeholder={1000} onBlur={(e)=>setInitialPrice(e.target.value)} />
                                <ErrorMessage name="price" component="p" className="text-red-500"/>
                            </li>
                            <li className="mt-2 w-[300px] flex  flex-col gap-1">
                                <p>Кнопки:</p>
                                <div className="opacity-80 w-[300px] flex h-12 bg-black justify-evenly items-center text-white text-[16px] font-medium rounded-[10px]">
                                    {stepArr.map((item,index)=>{
                                    return <input key={Math.random()} className="bg-white text-black w-[85px] h-5/6 rounded-[10px] text-center"  type="number" placeholder={item} onBlur={(e)=>setValueStep(e,index)}/>
                                    })}
                                    {stepArr.length < 3 &&
                                        <button className=" bg-green-700 text-black w-[85px] h-5/6 rounded-[10px] text-center" onClick={()=> setStepArr((oldArr)=>{let newArr = [...oldArr]; newArr.push(1); return newArr})}>+</button>
                                    }
                                </div>
                                <StepControle step={stepArr} initialPrice={initialPrice} setStepArr={setStepArr} />
                            </li>
                            <li className="mt-2 flex w-full flex-col gap-1 ">
                                <label htmlFor="price">Изображения первая из выбранных будт на главной :</label>
                                <input className='  rounded px-2 text-center'  type='file' multiple id="imgArr" name="imgArr"  onChange={(e)=>handleImageChange(e)}  />
                                
                                
                                {showImg && 
                                    <ul className="flex flex-col justify-center items-center gap-4">
                                        {showImg.map((urlImg,index)=>{
                                            return <li className='border-2 border-solid border-black ' key={index}><img className=" object-cover w-[400px] h-[300px]"  src={urlImg} alt='img'/></li>
                                        })}
                                    </ul>
                                    
                                } 
                            </li> 
                    </ul>

                    <ul className="mt-10 w-[500px] p-2 border-solid border-[1px] border-[grey] flex flex-col justify-start items-center">
                            <li className="flex w-full flex-col gap-1 ">
                                <label htmlFor="titleEN">Title :</label>
                                <Field className=' border border-solid border-[grey] rounded px-2 text-center'  as='input' id="titleEN" name="titleEN" />
                                <ErrorMessage name="titleEN" component="p" className="text-red-500"/>
                            </li>
                            <li className="flex w-full flex-col gap-1 ">
                                <label htmlFor="regionEN">Region :</label>
                                <Field className=' border border-solid border-[grey] rounded px-2 text-center'  as='input' id="regionEN" name="regionEN" />
                                <ErrorMessage name="regionEN" component="p" className="text-red-500"/>
                            </li>
                            <li className="flex w-full flex-col gap-1 ">
                                <label htmlFor="descriptionEN">Description :</label>
                                <Field  className='text-[16px] focus:h-[500px] overflow-hidden border border-solid border-[grey] rounded px-2' as='textarea' id="descriptionEN" name="descriptionEN" />
                                <ErrorMessage name="descriptionEN" component="p" className="text-red-500"/>
                            </li>
                            <li className="flex w-full flex-col gap-1 ">
                                <label htmlFor="route">Имя роута:</label>
                                <Field className=' border border-solid border-[grey] rounded px-2 text-center'  as='input' id="route" name="route" />
                                <ErrorMessage name="route" component="p" className="text-red-500"/>
                            </li>

                            <li>
                                <div className="mt-2 flex flex-col gap-2 border-y-[1px] border-[grey] py-2">
                                    {arrReq && arrReq.map((item)=>{
                                        return(
                                            <div key={item._id} className="flex justify-between items-center gap-1" >
                                                <img src={`${HOST}/api/products/img/${item.imgId[0]}`} className='w-[30px] h-[30px]' alt="REq" />
                                                <h4>{item.title}</h4>
                                                <p>{item.category}</p>
                                                <button onClick={()=> setArrReq((oldArr)=>oldArr.filter((oldItem)=>oldItem !== item))} type="button"><img src={`/cancel.svg`} className='w-[20px] h-[20px]' alt="Cancel" /></button>
                                            </div>
                                        )
                                    })}
                                </div>
                                
                                <button type="button" className="mt-2 p-2 bg-slate-200 rounded-xl" onClick={()=>setShowReq(true)}>Выбрать рекомендуеммые товары</button>
                                
                                {showReq && <InputReqProduct category='tea' setArrReq={setArrReq}/>}
                            </li>
                    </ul>
               </div>
               <button className="w-[300px] h-12 mt-5 mx-auto flex justify-center items-center text-[19px] text-white opacity-80 hover:opacity-100 rounded-[10px] font-semibold bg-[#0E8388]" type="submit" disabled={isSubmitting}>
                      Відправити
                </button>
            </Form>
        )}
        </Formik>
    )
        
}

export default CreateTea;