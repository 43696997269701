import DeleteOrder from "./DeleteOrder";
import SetOrder from "./SetOrder";
import PostOrder from "./PostOrder";
const ItemOrder = ({item,setOrders}) =>{
    const grivnaSymbol = '\u20B4';
    let sum=0;
    console.log(item)
    return(
        <li className=" border-b-2 border-black flex items-center" key={Math.random()} >
            <p className="w-[148px] border-r-[1px]  border-black">{item.firstName}</p>
            <p className="w-[150px] border-r-[1px] border-black">{item.lastName}</p>
            <p className="w-[150px] border-r-[1px] border-black">{item.methodDelivery}</p>
            <p className="w-[150px] border-r-[1px] border-black">{item.postOfficeNumber}</p> 
            <a className="w-[150px]  border-black" href={`tel:${item.phoneNumber}`}>{item.phoneNumber}</a>
            <ul className="flex flex-col gap-2 w-[500px] border-l-2 border-black">
                {item.arrCart.map((product)=>{
                    sum+=product.price
                    return (
                        <li className="flex items-center p-2" key={Math.random()}>
                            <img src={product.img} alt={product.title} width={50} height={50}/>
                            <p className="w-[200px] border-r-2">{product.title}</p>
                            <p className="w-[100px] border-r-2">{product.amount} <span>{product.amount > 1 ? 'грамм' : 'шт'}</span></p>
                            <p className="w-[100px] border-r-2">{product.price} <span>{grivnaSymbol}</span></p>
                        </li>
                    )
                })}
            </ul>
            <p className="w-[150px] ">{sum}{grivnaSymbol}</p>
            <div className=" w-[150px] flex justify-center gap-2 items-center">
                <PostOrder item = {item}/>
                <SetOrder  item={item}/>
                <DeleteOrder item={item} setOrders={setOrders}/>   
            </div>
        </li>
    )
};

export default ItemOrder;