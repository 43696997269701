import { useEffect, useState } from "react";
import fetchOrders from "../../service/orderApi";

import ItemOrder from "./ItemOrder";
const ShowOrders =() =>{
    const[orders,setOrders]=useState(null);
    const[err,setErr]= useState(null);
    useEffect(()=>{
        fetchOrders.getAll().then((data)=>data.length > 0 ? setOrders(data) : setErr('Пусто')).catch((err)=> setErr(err))
    },[])
   
    return(
        <>
            {orders === null && err === null && <div className=" h-screen flex justify-center items-center"><img   src="/preloader.gif" alt="preloader"/></div>}
            {orders !== null &&
                <ul className="flex flex-col">
                    <li className=" border-b-2 flex border-black">
                                <p className="w-[150px] border-r-2 border-black">Имя</p>
                                <p className="w-[150px] border-r-2 border-black">Фамилия</p>
                                <p className="w-[150px] border-r-2 border-black">Метод доставки</p>
                                <p className="w-[150px] border-r-2 border-black">Отделение</p>
                                <p className="w-[150px] border-r-2 border-black">Телефон</p>
                               
                                <p className="w-[500px] border-r-2 border-black">Товары</p>
                                <p className="w-[150px] border-r-2 border-black">Сумма</p>
                                <p className="w-[150px] border-r-2 border-black">Управление</p>
                    </li>
                    {orders.map((item)=>{
                        return <ItemOrder key={Math.random()} item={item} setOrders={setOrders}/>  
                    })}
                </ul>
            }
            {err !== null &&
                <p>Пусто</p>
            }
        </>    
    )
}

export default ShowOrders;