import { Formik, Form, Field, ErrorMessage} from 'formik';
import { useState } from "react";
import fetchProducts from '../../service/productApi';


const englishInputRegex = /^[a-zA-Z0-9`~!@#$%^&*()\-=+\[{}\]|;:'",<.>\/?\s]+$/;
const initialValues = {
    category:'teaware',
    type:'Gaiwan',
    title:'',
    
    country:'Ukraine',
    year:'',
    
    recommendationsArr:[],
    description:'',
    materials:'',
    amount: 1,
    price: 6666,
    
    show:true,

    titleEN: '',
    descriptionEN: '',
    regionEN:'',
    materialsEN:''
}
const imgArray=[];

const validateForm = values => {
    const errors = {};
    if (!values.title) {
      errors.title = 'Братик не втыкай заполни поле с названием товара';
    }
    if(englishInputRegex.test(values.title)){errors.title = 'Вводи на украинском';}
    if (!values.year) {
        errors.year = 'Яо какой год ооО ?';
    }
    if (!values.description) {
        errors.description = 'Вместо тысячи слов хотя бы одно';
    }
    if(englishInputRegex.test(values.description)){errors.description = 'Вводи на украинском';}
    if (!values.amount) {
        errors.amount = 'Хули тут так мало ?';
    }else if(typeof values.amount !== 'number'){
        errors.amount = 'Только цыфры'
    }
    if (!values.price) {
        errors.price = 'На пенек сел должен был цену указать';
    }
    if (!values.materials) {
        errors.materials = 'Из говна и палок ?';
    }
    if(englishInputRegex.test(values.materials)){errors.materials = 'Вводи на украинском';}
    if (!values.titleEN) {
        errors.titleEN = 'Введи назавние посуды на английском !';
      }
    if(!englishInputRegex.test(values.titleEN)){errors.titleEN = 'Вводи на английском ';}
    if (!values.materialsEN) {
        errors.materialsEN = 'Введи материал на английском !';
    }
    if(!englishInputRegex.test(values.materialsEN)){errors.materialsEN = 'Вводи на английском ';}
    if (!values.descriptionEN) {
        errors.descriptionEN = 'Введи описание на английском !';
      }
    // if(!englishInputRegex.test(values.descriptionEN)){errors.descriptionEN = 'Вводи на английском ';}
    if (!values.route) {
        errors.route = 'Введи путь для роутинга !';
    }
    return errors;
}



const CreateTeaware = () =>{
    const[showImg,setShowImg]=useState(null);
    const[showLoader,setLoader]=useState(false);
    const handleImageChange = (event) => {
        const files = Array.from(event.target.files);
        files.forEach((item)=>{
            imgArray.push(item)
        })
        const imageUrls = files.map((item) => URL.createObjectURL(item));
        setShowImg(imageUrls);
    };

    const handleSubmit = async(values, { setSubmitting,setValues }) => {
        const formData = new FormData();
        for (const key in values) {
            if (Object.hasOwnProperty.call(values, key)) {
                const element = values[key];
                formData.append(key,element)
            }
        }                         
                                    
        switch (values.type) {
            case 'Cups':
                formData.append('typeUA', 'Піали')
                break;
            case "Teapots":
                formData.append('typeUA', 'Чайники')
                break;
            case "Gaiwan":
                formData.append('typeUA', 'Гайвані')
                break; 
            case "Chahai":
                formData.append('typeUA', 'Чахаї')
                break;
            case "Chapan":
                formData.append('typeUA', 'Чабані')
                break;
            case "Pets":
                formData.append('typeUA', 'Чайні фігурки')
                break;  
            case "Accessories":
                formData.append('typeUA', 'Аксесуари')
                break; 
            case "Sets":
                formData.append('typeUA', 'Набори')
                break;
            default : formData.append('typeUA', 'Чайники')
               
        }
        switch (values.country) {
            case 'Ukraine':
                formData.append('countryUA', 'Україна')
                break;
            case 'Japan':
                formData.append('countryUA', 'Японія')
                break;
            case "China":
                formData.append('countryUA', 'Китай')
                break; 
            case "Taiwan":
                formData.append('countryUA', 'Тайвань')
                break;  
            default : formData.append('countryUA', 'Тайвань')  
        }
        
        if(imgArray.length > 0){
            imgArray.forEach((img)=>{
                formData.append('imgArr',img);
                
            })
            setLoader(true);
            await fetchProducts.push(formData);
            setLoader(false);
            window.location.reload();
          
        }else alert('Добавь изображения !')
    }
    return(
        <Formik
            initialValues={initialValues}
            validate={validateForm}
            onSubmit={handleSubmit}
            
        >
        {({ isSubmitting }) => (
            <Form className="pb-4">
                {showLoader &&
                    <div className='w-full h-screen top-0 z-10 flex justify-center items-center bg-slate-200 fixed'>
                        <img className='mx-auto ' src="/preloader.gif" alt="Load"/>
                    </div>
                }
                <div className="flex justify-center gap-4">
                    <ul className="mt-10 w-[500px] p-2 border-solid border-[1px] border-[grey] flex flex-col justify-start items-center gap-2">
                        <li className="flex w-full flex-col gap-1 ">
                            <label htmlFor="title">Название товара :</label>
                            <Field className=' border border-solid border-[grey] rounded px-2 text-center'  as='input' id="title" name="title" />
                            <ErrorMessage name="title" component="p" className="text-red-500"/>
                        </li>
                        
                        <li className="flex w-full flex-col gap-1 ">
                            <label htmlFor="type">Тип посуды :</label>
                            <Field className=' border border-solid border-[grey] rounded px-2 text-center' as='select' id="type" name="type" >
                                <option value="Cups">Пиала</option>
                                <option value="Teapots">Чайник</option>
                                <option value="Gaiwan">Гайвань</option>
                                <option value="Chahai">Чахай</option>
                                <option value="Chapan">Чабань</option>
                                <option value="Pets">Чайная фигурка</option>
                                <option value="Accessories">Аксессуар</option>
                                <option value="Sets">Набори</option>
                                
                            </Field>
                        </li>
                        <li className="flex w-full flex-col gap-1 ">
                            <label htmlFor="country">Страна производитель :</label>
                            <Field className=' border border-solid border-[grey] rounded px-2 text-center' as='select' id="country" name="country" >
                                <option value="Ukraine">Украина</option>
                                <option value="China">Китай</option>
                                <option value="Taiwan">Тайвань</option>
                                <option value="Japan">Япония</option>
                            </Field>
                        </li>
                        <li className="flex w-full flex-col gap-1 ">
                            <label htmlFor="materials">Материал :</label>
                            <Field className=' border border-solid border-[grey] rounded px-2 text-center'  as='input' id="materials" name="materials" />
                            <ErrorMessage name="materials" component="p" className="text-red-500"/>
                        </li>
                        <li className="flex w-full flex-col gap-1 ">
                            <label htmlFor="year">Год :</label>
                            <Field className=' border border-solid border-[grey] rounded px-2 text-center' as='input' type='number' id="year" name="year" />
                            <ErrorMessage name="year" component="p" className="text-red-500"/>
                        </li>
                        <li className="flex w-full flex-col gap-1 ">
                            <label htmlFor="description">Описание :</label>
                            <Field  className='text-[16px] focus:h-[500px] overflow-hidden border border-solid border-[grey] rounded px-2' as='textarea' id="description" name="description" />
                            <ErrorMessage name="description" component="p" className="text-red-500"/>
                        </li>
                        {/* <li className="flex w-full flex-col gap-1 ">
                            <label htmlFor="amount">Количество в шт :</label>
                            <Field className=' border border-solid border-[grey] rounded px-2 text-center'  as='input' type='number' id="amount" name="amount" />
                            <ErrorMessage name="amount" component="p" className="text-red-500"/>
                        </li> */}
                        <li className="flex w-full flex-col gap-1 ">
                            <label htmlFor="price">Цена за штуку :</label>
                            <Field className=' border border-solid border-[grey] rounded px-2 text-center'  as='input' type='number' id="price" name="price" placeholder={1000}/>
                            <ErrorMessage name="price" component="p" className="text-red-500"/>
                        </li>
                        <li className="mt-2 flex w-full flex-col gap-1 ">
                            <label htmlFor="price">Изображения первая из выбранных будет на главной :</label>
                            <input className='  rounded px-2 text-center'  type='file' multiple id="imgArr" name="imgArr"  onChange={(e)=>handleImageChange(e)}  />
                            
                            
                            {showImg && 
                                <ul className="flex flex-col justify-center items-center gap-4">
                                    {showImg.map((urlImg,index)=>{
                                        return <li className='border-2 border-solid border-black ' key={index}><img className=" object-cover"  src={urlImg} width={400} height={300} alt='img'/></li>
                                    })}
                                </ul>
                                
                            } 
                        </li>
                        <li className="flex w-full flex-col gap-1 ">
                            <label htmlFor="show">Отображение на сайте :</label>
                            <Field className=' border border-solid border-[grey] rounded px-2 text-center' as='select' id="show" name="show" >
                                <option value={true}>Показать на сайте</option>
                                <option value={false}>Скрыть</option>
                            </Field>
                        </li>
                        
                    </ul>

                    <ul className="mt-10 w-[500px] p-2 border-solid border-[1px] border-[grey] flex flex-col justify-start items-center">
                        <li className="flex w-full flex-col gap-1 ">
                            <label htmlFor="titleEN">Title :</label>
                            <Field className=' border border-solid border-[grey] rounded px-2 text-center'  as='input' id="titleEN" name="titleEN" />
                            <ErrorMessage name="titleEN" component="p" className="text-red-500"/>
                        </li>
                        <li className="flex w-full flex-col gap-1 ">
                            <label htmlFor="regionEN">Region :</label>
                            <Field className=' border border-solid border-[grey] rounded px-2 text-center'  as='input' id="regionEN" name="regionEN" />
                            <ErrorMessage name="regionEN" component="p" className="text-red-500"/>
                        </li>
                        <li className="flex w-full flex-col gap-1 ">
                            <label htmlFor="materialsEN">Materials :</label>
                            <Field className=' border border-solid border-[grey] rounded px-2 text-center'  as='input' id="materialsEN" name="materialsEN" />
                            <ErrorMessage name="materialsEN" component="p" className="text-red-500"/>
                        </li>
                        <li className="flex w-full flex-col gap-1 ">
                                <label htmlFor="descriptionEN">Description :</label>
                                <Field  className='text-[16px] focus:h-[500px] overflow-hidden border border-solid border-[grey] rounded px-2' as='textarea' id="descriptionEN" name="descriptionEN" />
                                <ErrorMessage name="descriptionEN" component="p" className="text-red-500"/>
                        </li>
                        <li className="flex w-full flex-col gap-1 ">
                            <label htmlFor="route">Имя роута:</label>
                            <Field className=' border border-solid border-[grey] rounded px-2 text-center'  as='input' id="route" name="route" />
                            <ErrorMessage name="route" component="p" className="text-red-500"/>
                        </li>
                        
                    </ul>
                </div>
                <button className="w-[300px] h-12 mt-5 mx-auto flex justify-center items-center text-[19px] text-white opacity-80 hover:opacity-100 rounded-[10px] font-semibold bg-[#0E8388]" type="submit" disabled={isSubmitting}>
                    Відправити
                </button>
            </Form>
        )}
        </Formik>
    )
}

export default CreateTeaware;